import { message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

export function differentObject(object, base, isBoolean = false) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (_.isArray(value)) {
        if (!_.isEqual(value, base[key])) {
          result[key] = value;
        }
      } else {
        if (!value && !base[key]) {
        } else {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              !(value instanceof Date) &&
              _.isObject(value) &&
              _.isObject(base[key])
                ? changes(value, base[key])
                : value;
          }
        }
      }
    });
  }
  const diff = changes(object, base);
  return isBoolean ? !_.isEmpty(diff) : diff;
}

export function unaccent(str) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
}

export const renderURL = (url, params, formatter) => {
  let paramsValue = _.compact(
    _.map(params, (p, key) => {
      if (p) {
        if (typeof p === 'object') {
          if (p.id) return `${key}=${p.id}`;
          else if (_.isArray(p)) {
            return `${key}=[${p}]`;
          } else {
            let formatDate = moment(p).format(
              formatter ? formatter : 'DD-MM-YYYY',
            );
            return `${key}=${formatDate}`;
          }
        } else {
          return `${key}=${p}`;
        }
      }
    }),
  );
  return _.map(paramsValue, (pv, index) => (!index ? pv : '&' + pv)).join('');
};
export const renderDateTimeInFilter = (value, formatter = 'YYYY-MM-DD') =>
  value ? moment(value).format(formatter) : undefined;

export function createFilterFromUrl(urlParams, arrayOfObjects = []) {
  let hashmap = _.reduce(
    arrayOfObjects,
    function (hash, value) {
      let key = value['key'];
      let type = value['type'];

      switch (type) {
        case 'number':
          hash[key] = urlParams?.get(key)
            ? +urlParams?.get(key)
            : value?.initValue;
          break;
        case 'string':
          hash[key] = urlParams?.get(key) || value?.initValue;
          break;
        case 'date':
          hash[key] = urlParams?.get(key)
            ? moment(urlParams?.get(key))
            : value?.initValue;
          break;
        case 'array':
          hash[key] = urlParams?.get(key)
            ? JSON.parse(urlParams?.get(key))
            : value?.initValue;
          break;
        default:
          hash[key] = undefined;
          break;
      }
      return hash;
    },
    {},
  );

  return hashmap;
}

export function dataFormat(data, callback, formatType = 'YYYY-MM-DD') {
  return _.mapValues(data, (item) => {
    if (
      moment(item, formatType, true).isValid() &&
      callback &&
      typeof callback === 'function'
    ) {
      return callback(item);
    }

    // if (Array.isArray(item) && isSubmit) {
    //   return _.isEmpty(item) ? undefined : JSON.stringify(item);
    // }

    // // https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string
    // if (
    //   !isSubmit &&
    //   /^[\],:{}\s]*$/.test(
    //     (item + "")
    //       .replace(/\\["\\\/bfnrtu]/g, "@")
    //       .replace(
    //         /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
    //         "]"
    //       )
    //       .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    //   )
    // ) {
    //   let parseItem = JSON.parse(item);
    //   if (Array.isArray(parseItem)) {
    //     return _.isEmpty(parseItem) ? undefined : parseItem;
    //   } else {
    //     return item;
    //   }
    // }

    return item;
  });
}

export function range(start, end) {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

export function disabledTime(
  _,
  type,
  hourLimit = 0,
  dateArr = [],
  nowValue = undefined,
  addMore = 0,
) {
  const selectedDate = moment(_);
  const now = moment(nowValue).add(addMore, 'minutes'); // Add extra minutes to the current time
  const nowHour = now.hour();
  const newHour = nowHour + hourLimit;

  const disabledTimeRange = (hour, minute, isPast = true) => ({
    disabledHours: () => (isPast ? range(0, hour - 1) : range(hour + 1, 23)),
    disabledMinutes: () => {
      if (selectedDate.hour() === hour) {
        return isPast ? range(0, minute) : range(minute, 59);
      } else {
        return [];
      }
    },
  });

  if (selectedDate.isSame(now, 'day')) {
    return disabledTimeRange(newHour, now.minute());
  }

  if (Array.isArray(dateArr) && dateArr.length > 0) {
    for (const config of dateArr) {
      const { date, isPast } = config || {};
      const dateMoment = moment(date).add(addMore, 'minutes'); // Add extra minutes to the date in the array

      const dateHour = dateMoment.hour();
      const newDateHour = dateHour + hourLimit;

      if (selectedDate.isSame(dateMoment, 'day')) {
        return disabledTimeRange(newDateHour, dateMoment.minute(), isPast);
      }
    }
  }

  return {};
}

export const handleOrderBy = (sorter, sortingKey) => {
  const orderKey = sortingKey[sorter?.columnKey];
  const order = sorter?.order ? sorter?.order?.slice(0, -3) : undefined; // ascend => asc, descend => desc

  return orderKey && order ? `${orderKey} ${order}` : undefined;
};

export const returnDefaultSortOrder = (key, sortingKey, params) => {
  const orderByStr = params?.order_by;

  if (!orderByStr) {
    return undefined;
  }

  const [orderKey, order] = orderByStr.split(' ');

  if (orderKey === sortingKey[key]) {
    switch (order) {
      case 'asc':
        return 'ascend';
      case 'desc':
        return 'descend';
      default:
        return undefined;
    }
  }

  return undefined;
};

export const disabledDate = (dateFromPicker, isToday = true) => {
  return (
    dateFromPicker &&
    dateFromPicker < moment()[isToday ? 'startOf' : 'endOf']('day')
  );
};

const formatFileSize = (fileSize) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;

  while (fileSize >= 1024 && unitIndex < units.length - 1) {
    fileSize /= 1024;
    unitIndex++;
  }

  const formattedSize = fileSize.toFixed(2) + ' ' + units[unitIndex];

  return formattedSize;
};

export const checkExcelFileType = (file) => {
  const isExcel =
    file?.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file?.type === 'application/vnd.ms-excel';

  if (!isExcel) {
    message.error('Bạn chỉ được phép tải file Excel (.xls, .xlsx)!');
  }

  return isExcel;
};

export const checkJpgOrPngFileType = (file) => {
  const isJpgOrPng = file?.type === 'image/jpeg' || file?.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Bạn chỉ được phép tải hình JPG/PNG!');
  }

  return isJpgOrPng;
};

export const validateFileSize = (file, fileSize = 1024 * 1024 * 25) => {
  // default 25MB
  const valid = file?.size <= fileSize;
  if (!valid) {
    message.error(`Kích thước file phải nhỏ hơn ${formatFileSize(fileSize)}`);
  }

  return valid;
};

export const findInArrayByKey = (
  array,
  comparativeValue,
  comparativeField = 'id',
  returnKey,
) => {
  if (!_.isArray(array) || array?.length === 0) return;

  const result = array.find(
    (item) => item?.[comparativeField] === comparativeValue,
  );
  return result && returnKey ? result?.[returnKey] : result;
};

export const handleStaticSearch = (input, option, key = 'name') => {
  const searchValue = unaccent(input)?.toLowerCase();
  const name = unaccent(option?.[key])?.toLowerCase() || '';

  return name?.indexOf(searchValue) >= 0;
};

export const formatDateForSubmit = (
  date,
  rangeKeys = [],
  returnArray = false,
) => {
  if (_.isArray(date) && date.length === 2 && rangeKeys.length === 2) {
    const [startKey, endKey] = rangeKeys;
    const [startDate, endDate] = date;

    const startDateFormat = renderDateTimeInFilter(
      startDate,
      'YYYY-MM-DDTHH:mm:ss',
    );
    const endDateFormat = renderDateTimeInFilter(
      endDate,
      'YYYY-MM-DDTHH:mm:ss',
    );

    if (returnArray) {
      return [startDateFormat, endDateFormat];
    } else {
      return { [startKey]: startDateFormat, [endKey]: endDateFormat };
    }
  } else {
    return renderDateTimeInFilter(date, 'YYYY-MM-DDTHH:mm:ss');
  }
};

export const flattenDataArrays = (
  data,
  dateKeyList = [],
  formatter = 'DD/MM/YYYY',
) =>
  _.reduce(
    data,
    (result, obj) => {
      _.forEach(obj, (value, key) => {
        let newValue = value;

        if (_.isArray(dateKeyList) && dateKeyList.includes(key)) {
          newValue = moment(value).format(formatter);
        }

        if (value && typeof value === 'number') {
          newValue = +numeral(value).format('0[.][00]');
        }

        if (_.isArray(result[key])) {
          result[key].push(newValue);
        } else {
          result[key] = [newValue];
        }
      });
      return result;
    },
    {},
  );

export const formLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
  colon: false,
  labelAlign: 'left',
  labelWrap: true,
};

export const dateFormat = (date, formatter = 'DD/MM/YYYY', failRender) =>
  date ? moment(date).format(formatter) : failRender;

export const extendClassName = ({ init, extendClass }) =>
  `${init}${extendClass ? ' ' + extendClass : ''}`;

export const getValueFromValueOrArray = ({ value, index }) => {
  if (_.isArray(value)) {
    return value[index || 0];
  }

  return value;
};

export const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

export const numberFormat = (value, formatter = '0,0[.][00]') =>
  +value ? numeral(value).format(formatter) : 0;

export const setStorage = (key, value) => {
  const newValue = typeof value === 'object' ? JSON.stringify(value) : value;
  localStorage.setItem(key, newValue);
};

export const getStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch {
    return localStorage.getItem(key);
  }
};

export const char = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const handleEnum = (enumValue = {}, valueIterateeCustom = 'value') => {
  const list = Object.values(enumValue);
  const object = _.keyBy(enumValue, valueIterateeCustom);

  return [list, object];
};

export function flattenArray(arr) {
  return arr.reduce(
    (acc, val) =>
      Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val),
    [],
  );
}

export function getFileNameFromExportAPI(response) {
  let renameFile;

  const contentDispositionHeader = response.headers['content-disposition'];
  const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const fileNameMatches = fileNameRegex.exec(contentDispositionHeader);

  if (fileNameMatches != null && fileNameMatches[1]) {
    renameFile = decodeURIComponent(fileNameMatches[1].replace(/['"]/g, ''));
  }
  return renameFile;
}

export function downloadFile(fileName, blobData) {
  const url = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export function mapPermissionsToTreeData(permissions) {
  return permissions.map((permission) => ({
    title: permission.name,
    key: permission.id,
    dependencyChildPermissionDtods:
      permission?.dependencyChildPermissionDtos || [],
    children: mapPermissionsToTreeData(permission.childrenPermissionDtos || []),
  }));
}

export const mergeAndRemoveDuplicates = (array1, array2) => {
  // Merge the two arrays
  const mergedArray = array1.concat(array2);

  // Remove duplicates using Set
  const uniqueArray = [...new Set(mergedArray)];

  return uniqueArray;
};

export function flattenPermissions(permission) {
  const result = {};

  function traverse(node) {
    result[node.key] = node.id;
    if (node.childrenPermissionDtos && node.childrenPermissionDtos.length > 0) {
      node.childrenPermissionDtos.forEach((child) => traverse(child));
    }
  }
  permission.forEach((item) => traverse(item));
  return result;
}

export function flattenPermissionsWithParent(groups) {
  const result = {};

  function traverse(node, parentKey) {
    if (parentKey !== null) {
      result[node.id] = parentKey;
    }
    if (node.childrenPermissionDtos && node.childrenPermissionDtos.length > 0) {
      node.childrenPermissionDtos.forEach((child) => traverse(child, node.id));
    }
  }

  groups.forEach((group) => {
    group.permissionDtos.forEach((permission) => traverse(permission, null));
  });

  return result;
}

export function getCurrentPermissions(node) {
  let result = [];

  if (node.isCurrentPermission) {
    result.push(node.id);
  }

  if (node.childrenPermissionDtos && node.childrenPermissionDtos.length > 0) {
    node.childrenPermissionDtos.forEach((child) => {
      result = result.concat(getCurrentPermissions(child));
    });
  }

  return result;
}

export function getCurrentChildPermissions(node, isUsingParentNode = false) {
  let result = [];

  // For permission doesn't have children
  if (
    node?.isCurrentPermission &&
    node?.childrenPermissionDtos &&
    node?.childrenPermissionDtos?.length === 0
  ) {
    result.push(node.id);
  }

  // For permission has children
  if (node.childrenPermissionDtos && node.childrenPermissionDtos.length > 0) {
    if (isUsingParentNode && node.isCurrentPermission) {
      result.push(node.id);
    }
    node.childrenPermissionDtos.forEach((child) => {
      if (child.isCurrentPermission) {
        result.push(child.id);
      }
      result = result.concat(getCurrentChildPermissions(child));
    });
  }

  return Array.from(new Set(result));
}
export function getCurrentChildPermissionsInArray(data) {
  const result = [];

  function traverse(node) {
    // Include node if it has no children and isCurrentPermission is true
    if (
      (!node.childrenPermissionDtos ||
        node.childrenPermissionDtos.length === 0) &&
      node.isCurrentPermission
    ) {
      result.push(node.id);
    } else {
      // Traverse children if any
      node.childrenPermissionDtos?.forEach((child) => traverse(child));
    }
  }

  data.forEach((group) => {
    group.permissionDtos.forEach((permission) => traverse(permission));
  });

  return result;
}

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  // Sort the arrays
  const sortedArr1 = [...arr1].sort((a, b) => a - b);
  const sortedArr2 = [...arr2].sort((a, b) => a - b);

  // Compare sorted arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) return false;
  }

  return true;
};

export function cleanHTML(htmlString) {
  // Replace escaped quotes \" with normal quotes "
  const cleanedHTML = htmlString.replace(/\\"/g, '"');

  // Optionally, you can also sanitize the HTML if needed to avoid XSS
  return cleanedHTML;
}

export const disableCurrentToPastTime = (selectedDate) => {
  const currentDate = moment();

  // Chỉ disable thời gian nếu ngày được chọn là ngày hiện tại
  if (selectedDate && selectedDate.isSame(currentDate, 'day')) {
    return {
      disabledHours: () =>
        Array.from({ length: currentDate.hour() }, (_, i) => i),
      disabledMinutes: (selectedHour) => {
        // Chỉ disable phút nếu giờ được chọn là giờ hiện tại
        if (selectedHour === currentDate.hour()) {
          return Array.from({ length: currentDate.minute() }, (_, i) => i);
        }
        return []; // Không disable phút nếu giờ lớn hơn giờ hiện tại
      },
      disabledSeconds: (selectedHour, selectedMinute) => {
        // Chỉ disable giây nếu cả giờ và phút trùng với giờ và phút hiện tại
        if (
          selectedHour === currentDate.hour() &&
          selectedMinute === currentDate.minute()
        ) {
          return Array.from({ length: currentDate.second() }, (_, i) => i);
        }
        return []; // Không disable giây nếu phút lớn hơn phút hiện tại
      },
    };
  }
  return {};
};

export const disableCurrentToPassDate = (current) => {
  return current && current < moment().startOf('day');
};

export function cleanHtmlCode(input) {
  // Create a DOMParser to parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');

  // Remove all inline style attributes
  const elementsWithStyle = doc.querySelectorAll('*[style]');
  elementsWithStyle.forEach((element) => {
    element.removeAttribute('style');
  });

  // Remove all <img> tags
  const images = doc.querySelectorAll('img');
  images.forEach((img) => {
    img.remove();
  });

  // Keep only <p>, <i>, <strong>, <b>, and <a> tags
  const allowedTags = ['P', 'I', 'STRONG', 'B', 'A'];
  const allElements = doc.body.querySelectorAll('*'); // Select all elements
  allElements.forEach((element) => {
    if (!allowedTags.includes(element.tagName)) {
      // If the tag is not allowed, replace it with its children
      while (element.firstChild) {
        element.parentNode.insertBefore(element.firstChild, element);
      }
      element.remove(); // Remove the element
    }
  });

  // Return the cleaned HTML string
  return doc.body.innerHTML;
}

export const returnImageSrc = (value) => {
  return value?.includes('https://')
    ? value
    : process.env.REACT_APP_SHOW_IMAGE + value;
};
